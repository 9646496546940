export function currencyFormat(value: number | string) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function unCurrencyFormat(value: string) {
  return value.replaceAll(',', '');
}

export function getEnNumberWithSuffix(num: number | string) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = +num % 100;
  return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

export function optionalStringToNumbrer(value?: string) {
  return value ? +value : 0;
}

export function currencyFormatNumber(num: number): string {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(0)}K`;
  }
  return num.toString();
}

export function roundIfCloseToSmallFraction(value: number): number {
  const fractionalPart = value % 1;
  return fractionalPart <= 1e-12 ? Math.floor(value) : value;
}

export function round2Decimals(value: number) {
  return Math.round(value * 100) / 100;
}
